import { utils } from '../_helpers/utils'

export const shortLinkService = {
    getFullLink
};

function getFullLink(params) {
    return utils.request({
        methodName: '/ShortLink/GetFullLink',
        method: 'GET',
        queryString: params
    });
}
