import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { externalService } from '../../_services/external.service';
import { utils } from '../../_helpers/utils';
import "@progress/kendo-ui";
import '@progress/kendo-ui/js/cultures/kendo.culture.ro-RO.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import { errorMessages } from "../../_helpers/errorMessages.js"
import { SpeechRecognitionHandler } from './speechRecognition.js';
import { ExportForm } from './exportForm.js';
import { CustomIframe } from './customIframe';
import { initFormFields, runOnErrorImage, adjustButtonsSize, initDropdownList } from "../../_helpers/iFrame";

export const ExternalTestForm = (props) => {
    const params = useParams();

    const language = params.language ? params.language : "RO";
    const shortLink = params.shortLink;

    const hideQrCode = props && props.hideQrCode;

    const [errorContent, setErrorContent] = React.useState(false);
    const [htmlContent, setHtmlContent] = React.useState("");
    const [dropdownList, setDropdownList] = React.useState([]);
    const [speechRecognitionElement, setSpeechRecognitionElement] = React.useState(null);

    const imagesNotFound = useRef([]);

    const thisComponent = {
        preview: true,
        language: language,
        dropdownList: dropdownList,
        setDropdownList: setDropdownList,
        setSpeechRecognitionElement: setSpeechRecognitionElement
    };

    // #region init
    useEffect(() => {
        // setam culture in functie de language
        setCurrentCulture();

        if (!shortLink) {
            utils.alert.set({
                title: errorMessages.errorName[language],
                text: errorMessages.invalidUrl[language]
            });

            // eroare 
            setHtmlContent(errorMessages.generalError[language]);
            setErrorContent(true);

            return;
        }

        externalService.initTestForm(
            {
                shortLink: shortLink,
                language: language
            })
            .then(response => {
                if (!response || !response.htmlContent) {
                    // eroare 
                    setHtmlContent(errorMessages.generalError[language]);
                    setErrorContent(true);

                    adjustButtonsSize();

                    return;
                }
                else {
                    setHtmlContent(response.htmlContent);
                }
            });

    }, []);

    const handleOnInit = () => {
        runOnErrorImage();
        initFormFields(thisComponent);
    }

    useEffect(() => {
        if (dropdownList?.length == 0) { return; }

        initDropdownList(thisComponent);

    }, [dropdownList]);

   
    const setCurrentCulture = () => {
        let _lang = '';
        switch (language) {
            case "EN":
                _lang = 'en-US';
                break;
            case 'FR':
                _lang = 'fr-FR';
                break;
            default:
                _lang = 'ro-RO';
        }

        if (kendo) {
            kendo.culture(_lang);
        }
    }

    return (<div>
        <div className="k-card">
            <div className="k-card-body" >
                {htmlContent && <CustomIframe htmlContent={htmlContent} expand={true} handleOnInit={handleOnInit} />}
            </div>
            {!errorContent &&
                <ExportForm
                preview={true}
                imagesNotFound={imagesNotFound}
                language={language}
                initDataLoaded={true}
                hideQrCode={hideQrCode}
                />}
        </div>

        {speechRecognitionElement && <SpeechRecognitionHandler element={speechRecognitionElement} />}

    </div>
    );
};
