import React from "react";
import { Input, TextArea, Checkbox } from "@progress/kendo-react-inputs";
//import { ComboBox, MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns";
//import { TimePicker, DatePicker } from "@progress/kendo-react-dateinputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { validator } from "../../_helpers/validator";

export const ValidatedInput = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <Input {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

/*export const ValidatedDropDownList = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <DropDownList {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};*/

/*export const ValidatedComboBox = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <ComboBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};*/

/*export const ValidatedMultiSelect = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <MultiSelect {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};*/

/*export const ValidatedTimePicker = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <TimePicker {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedDatePicker = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <DatePicker {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};*/

export const ValidatedTextArea = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <Label>
                {fieldRenderProps.label}
            </Label>
            <TextArea {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedCheckBox = (fieldRenderProps) => {
    const { visited, ...others } = fieldRenderProps;
    let validationMessage = validator.validateField(fieldRenderProps);

    return (
        <div>
            <Checkbox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};
