import React, { useState, useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { onSpeechInputChange, getIframeBody } from "../../_helpers/iFrame";


export const SpeechRecognitionHandler = (props) => {

    const [currentSpeechInput, setCurrentSpeechInput] = useState();
    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {

        // daca browserul nu suporta SpeechRecognition -> iesim
        if (!browserSupportsSpeechRecognition) {
            return;
        }

        let element = props.element;
        let parent = element.parentNode;
        let wrapper = document.createElement('div');
        wrapper.style = element.style;
        wrapper.classList.add('parent-node');

        // set the wrapper as child (instead of the element)
        parent.replaceChild(wrapper, element);
        // set element as child of wrapper
        wrapper.appendChild(element);

        // icon
        let icon = document.createElement('span');
        icon.classList.add('k-icon');
        icon.classList.add('icon-mic');

        if (element.tagName.toLowerCase() === 'input') {
            icon.classList.add('input-icon-mic');
        }

        icon.onclick = onSpeechClick;
        icon.setAttribute('parent-name', element.getAttribute('name'));
        wrapper.appendChild(icon);

    }, [props.element]);

    useEffect(() => {
        if (currentSpeechInput) {
            onSpeechInputChange(currentSpeechInput);
        }
    }, [transcript]);


    const onSpeechClick = (event) => {

        if (event.target.classList.contains('listening')) {
            setCurrentSpeechInput(null);
            SpeechRecognition.stopListening();
            event.target.classList.remove('listening');
        }
        else {
            // a fost selectat anterior un alt input/text
            let _elems = getIframeBody().find('.k-icon.icon-mic.listening');
            for (let i = 0; i < _elems.length; i++) {
                _elems[i].classList.remove('listening');
            }

            setCurrentSpeechInput(event.target.getAttribute('parent-name'));
            resetTranscript();
            event.target.classList.add('listening');

            let _lang;
            switch (props.language) {
                case "EN":
                    _lang = 'en-US';
                    break;
                case 'FR':
                    _lang = 'fr-FR';
                    break;
                default:
                    _lang = 'ro-RO';
            }

            SpeechRecognition.startListening({ continuous: true, language: _lang });
        }
    }

    return false;

}