import $ from "expose-loader?exposes=$,jQuery!jquery";
import moment from 'moment';
import { utils } from './utils';
import { errorMessages } from './errorMessages';

const imgTransparent = '/images/transparent.png';

export const getIframeBody = (iframeId = 'formHtmlContent') => {
    return $('#' + iframeId).contents().find('body');
}

export const getIframeHead = (iframeId = 'formHtmlContent') => {
    return $('#' + iframeId).contents().find('head');
}

export const adjustButtonsSize = () => {
    let buttons = ['#_saveFormButton', '#_qrCodeButton', '#_printFormButton', '#_downloadPdfButton'];
    let maxWidth = 0;

    for (let i = 0; i < buttons.length; i++) {
        let _width = $(buttons[i]).width();
        if (_width > maxWidth) {
            maxWidth = _width;
        }
    }

    // cast as int
    maxWidth = parseInt(maxWidth) + 1;

    for (let i = 0; i < buttons.length; i++) {
        $(buttons[i]).width(maxWidth);
    }
}

export const getDropdownItemWidth = (input, value) => {
    let style = window.getComputedStyle(input, null);
    let fontSize = parseFloat(style.getPropertyValue('font-size'));
    let fontFamily = style.getPropertyValue('font-family');
    let paddingLeft = style.getPropertyValue('padding-left');
    let paddingRight = style.getPropertyValue('padding-right');

    let spanStyle = {
        'position': 'absolute',
        'float': 'left',
        'white-space': 'nowrap',
        'visibility': 'hidden',
        'font-size': fontSize + 'px',
        'font-family': fontFamily,
        'padding-left': paddingLeft,
        'padding-right': paddingRight
    };

    let span = $('<span></span>')
        .text(value)
        .css(spanStyle)
        .appendTo(getIframeBody());

    let width = span.width();
    if (!isNaN(parseFloat(paddingLeft))) { width += parseFloat(paddingLeft); }
    if (!isNaN(parseFloat(paddingRight))) { width += parseFloat(paddingRight); }

    // remove span
    span.remove();
    return width;
}

export const setDropdownDataSource = (name, type, dataSource) => {

    let comboboxType = type == 'dropdown' ? 'kendoDropDownList' :
        type == 'multiselect' ? 'kendoMultiSelect' : null;

    if (!comboboxType) return;

    let combobox = getIframeBody().find('input[name="' + name + '"]').data(comboboxType);
    if (!combobox) {
        return;
    }

    combobox.setDataSource(dataSource);
    combobox.enable(dataSource?.length > 0);
}


export const setInitialDataSource = (element, type, value) => {
    if (!value) {
        return;
    }

    let comboboxType = type == 'dropdown' ? 'kendoDropDownList' :
        type == 'multiselect' ? 'kendoMultiSelect' : null;

    if (!comboboxType) return;

    let combobox = element.data(comboboxType);
    if (!combobox) {
        return;
    }

    if (type == 'dropdown') {
        combobox.setDataSource([{ code: value, display: '' }]);
        combobox.value(value);

    }
    else if (type == 'multiselect') {
        if (typeof value == 'string') {
            combobox.setDataSource(value.split(',').map(x => { return { code: x, display: '' } }));
            combobox.value(value.split(','));
        }
        else {
            combobox.setDataSource([{ code: value, display: '' }]);
            combobox.value(value);
        }
    }

    // dropdownul ramane disabled pana se aduc dataSoure-urile de pe server
    combobox.enable(false);
}

export const enableKendoDropdown = (name, type, enable) => {
    let comboboxType = type == 'dropdown' ? 'kendoDropDownList' :
        type == 'multiselect' ? 'kendoMultiSelect' : null;

    if (!comboboxType) return;

    let combobox = getIframeBody().find('input[name="' + name + '"]').data(comboboxType);
    if (!combobox) {
        return;
    }

    combobox.enable(enable);
}

export const getFormField = (element, formComponent) => {
    if (!element) {
        return null;
    }

    let _innerName = null;
    let _type = element.type;

    switch (_type) {
        case 'radio':
            // verificam daca e radio de tip yes/no
            let _yesNo = findYesNoElement(element.getAttribute('name'))
            if (_yesNo) {
                _innerName = null;
            }
            else {
                _innerName = element.value;
            }

            break;

        case 'checkbox':
            _innerName = null;
            break;

        default:
            break;
    }

    let key = element.getAttribute('name') + (_innerName ? "@|!|@" + _innerName : '');

    if (formComponent.preview) {
        return {
            htmlTagName: key
        }
    }

    return formComponent.formFields.find(x => {
        if (x.htmlTagName == key) {
            return true;
        }
    });
}

const getElementType = (element) => {
    return element.getAttribute('dropdown') ? 'dropdown' :
        element.getAttribute('multiselect') ? 'multiselect' :
            element.getAttribute('data-role') == 'datepicker' ? 'date' :
                element.getAttribute('data-role') == 'timepicker' ? 'time' :
                    element.getAttribute('data-role') == 'datetimepicker' ? 'datetime' :
                        element.getAttribute('type') && element.tagName.toLowerCase() === 'input' ? element.getAttribute('type').toLowerCase() :
                            element.type;
}

export const initFormFields = (formComponent) => {
    var elements = getIframeBody().find('[name]');
    var dropdownList = [];

    for (let i = 0; i < elements.length; i++) {
        let _element = elements[i];
        let _type = getElementType(_element);
        let _dataSource;

        // transformam select in input
        if (_type == 'select-one' || _type == 'select-multiple') {
            let _replaceSelect = replaceSelectTagAndGetDataSource(_element);
            _element = _replaceSelect.element;
            _type = _replaceSelect.type;
            _dataSource = _replaceSelect.dataSource;
        }

        if (formComponent.preview && _element.list) {
            _dataSource = [];
            for (let option of _element.list.options) {
                _dataSource.push({
                    display: option.value
                });
            }
        }
        
        let _field = getFormField(_element, formComponent);
        if (!_field) { continue; }

        if (_type == 'dropdown' || _type == 'multiselect') {
            dropdownList.push({
                name: _field.htmlTagName,
                formFieldId: _field.formFieldId,
                type: _type,
                parent: _type == 'dropdown' ? _element.getAttribute('dropdown-parent') : null,
                dataSource: _dataSource
            });

            // daca e group parent si dropdown sau multiselect
            if (_element.getAttribute('grouping-parent')) {
                checkDropdownGrouping(_element, _field.value);
            }
        }

        if (_element.tagName.toLowerCase() === 'input') {
            if (_type == 'date') {
                initKendoDatePicker(_element);
            }
            else if (_type == 'datetime') {
                initKendoDateTimePicker(_element);
            }
            else if (_type == 'time') {
                initKendoTimePicker(_element);
            }
        }

        // daca avem response - setam ce vine de pe server - chiar daca e null
        if (formComponent?.formResponseId || _field.value) {
            setElementValue(_element, _field.value);

            // trigger on change
            if (_element) {
                if ("createEvent" in document) {
                    var evt = document.createEvent("HTMLEvents");
                    evt.initEvent("change", false, true);
                    _element.dispatchEvent(evt);
                }
                else {
                    _element.fireEvent("onchange");
                }
            }
        }


        if (_type == 'textarea') {
            let _initialHeight = _element.offsetHeight;
            _element.style.setProperty("overflow-y", "hidden");

            refreshTextareaHeight(_element, _initialHeight);

            _element.addEventListener('input', (function (initialHeight) {
                return function (event) {
                    refreshTextareaHeight(event.target, initialHeight);
                }
            })(_initialHeight));
        }

        let inputNotRefresh = ['checkbox', 'radio', 'date', 'time', 'datetime'];
        if (_element.tagName.toLowerCase() === 'input' &&
            !inputNotRefresh.some(x => x == _type)) {
            let _initialWidth = _element.offsetWidth;
            refreshInputWidth(_element, _initialWidth);

            _element.addEventListener('input', (function (initialWidth) {
                return function (event) {
                    refreshInputWidth(event.target, initialWidth);
                }
            })(_initialWidth));
        }

        if (!_element.disabled && !formComponent.preview) {
            _element.disabled = formComponent.isFinalized;
        }        

        // input care are onchange pe el - sa nu suprascrie onchange urile deja existente
        if (_element.onchange) {
            _element.onchange = (function (onchange) {
                return function (event) {
                    onInputChange(event, formComponent);

                    if (onchange) {
                        onchange(event);
                    }
                }
            })(_element.onchange);
        }
        else {
            _element.onchange = (event) => onInputChange(event, formComponent);
        }

        let speechrecognition = _element.getAttribute('speechrecognition');
        if (speechrecognition) {
            formComponent.setSpeechRecognitionElement(_element);
        }
    }

    formComponent?.setDropdownList(dropdownList);

    if (!formComponent.preview) {
        // signatures
        var signatureElements = getIframeBody().find('[signature]');
        for (let i = 0; i < signatureElements.length; i++) {
            let _signatureElement = signatureElements[i];

            _signatureElement.src = imgTransparent;

            if (_signatureElement.onclick) {
                _signatureElement.onclick = (function (onclick) {
                    return function (event) {
                        formComponent.onSignatureClick(event);

                        if (onclick) {
                            onclick(event);
                        }
                    }
                })(_signatureElement.onclick);
            }
            else {
                _signatureElement.onclick = formComponent.onSignatureClick;
            }
        }

    
        var reinitializeElements = getIframeBody().find('button[reinit-sqldata]');
        for (let i = 0; i < reinitializeElements.length; i++) {
            let _reinitializeElements = reinitializeElements[i];

            if (_reinitializeElements.onclick) {
                _reinitializeElements.onclick = (function (onclick) {
                    return function (event) {
                        formComponent.OnReinitSqlDataClick(event);

                        if (onclick) {
                            onclick(event);
                        }
                    }
                })(_reinitializeElements.onclick);
            }
            else {
                _reinitializeElements.onclick = formComponent.OnReinitSqlDataClick;
            }
        }
    }
}

const replaceSelectTagAndGetDataSource = (selectElement) => {
    let inputElement = document.createElement("input");
    let _type = selectElement.type;

    if (_type == 'select-one') {
        _type = 'dropdown';
    }

    if (_type == 'select-multiple') {
        _type = 'multiselect';
    }

    for (let i = 0; i < selectElement.attributes.length; i++) {
        let attr = selectElement.attributes[i];
        inputElement.setAttribute(attr.name, attr.value);
    }

    // setam tipul de dropdown sau multiselect
    inputElement.setAttribute(_type, "true");

    // extragem dataSource-ul
    var dataSource = [];

    for (var i = 0; i < selectElement.options.length; i++) {
        if (selectElement.options[i].value) {
            dataSource.push({
                display: selectElement.options[i].text,
                code: selectElement.options[i].value
            });
        }
    }

    // inlocuim select cu input
    selectElement.parentNode.replaceChild(inputElement, selectElement);

    return {
        element: inputElement,
        type: _type,
        dataSource: dataSource
    };
}

const findYesNoElement = (name) => {
    let _elements = getIframeBody().find(`[name="${name}"]`);
    let _yes, _no;

    if (_elements.length == 2) {
        for (let i = 0; i < _elements.length; i++) {
            if (_elements[i].value?.toLowerCase() == 'yes') {
                _yes = _elements[i];
            }
            else if (_elements[i].value?.toLowerCase() == 'no') {
                _no = _elements[i];
            }
        }
    }

    if (_yes && _no) {
        return {
            yes: _yes,
            no: _no
        }
    }

    return null;
}

export const clearPulseValidation = () => {
    var elements = getIframeBody().find('[name]');
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('pulse');
    }

    let signatureElements = getIframeBody().find('[signature]');
    for (let i = 0; i < signatureElements.length; i++) {
        signatureElements[i].classList.remove('pulse');
    }
}

export const setElementValue = (element, value) => {
    if (!element) {
        return;
    }

    let _type = getElementType(element);

    switch (_type) {
        case 'radio':
            let _checked = value && (value === true || parseInt(value) > 0 || value.toLowerCase() == "true");

            // verificam daca e radio de tip yes/no
            let _yesNo = findYesNoElement(element.getAttribute('name'))
            if (_yesNo) {
                if (value == null) {
                    _yesNo.yes.checked = false;
                    _yesNo.no.checked = false;
                }
                else if (_checked) {
                    _yesNo.yes.checked = true;
                    _yesNo.no.checked = false;
                }
                else {
                    _yesNo.yes.checked = false;
                    _yesNo.no.checked = true;
                }
            }
            else {
                element.checked = _checked;
            }
            break;

        case 'checkbox':
            element.checked = value && (value === true || parseInt(value) > 0 || value.toLowerCase() == "true");
            break;

        case 'dropdown':
            let combobox = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]').data("kendoDropDownList");

            // set value & verificare buton clear
            if (combobox) {
                combobox.value(value);
                showHideDropdownClearButton(combobox, value);
            }
            break;

        case 'multiselect':
            let multiselect = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]').data("kendoMultiSelect");
            if (value) {
                if (typeof value == 'string') {
                    multiselect?.value(value.split(','));
                }
                else {
                    multiselect?.value(value);
                }
            } else {
                multiselect?.value([]);
            }
            break;

        case 'date':
            let datepicker = $(element).getKendoDatePicker();
            if (datepicker && value) {
                datepicker.value(new Date(value));
            }
            else {
                element.value = value;
            }
            break;

        case 'time':
            let timepicker = $(element).getKendoTimePicker();
            if (timepicker && value) {
                timepicker.value(new Date(value));
            }
            else {
                element.value = value;
            }
            break;

        case 'datetime':
            let datetimepicker = $(element).getKendoDateTimePicker();
            if (datetimepicker && value) {
                datetimepicker.value(new Date(value));
            }
            else {
                element.value = value;
            }
            break;

        default:
            // img
            if (element instanceof HTMLImageElement || element.tagName.toLowerCase() === 'img') {
                element.src = value ? value : imgTransparent;
            }
            else {
                element.innerText = value;
                element.value = value;
            }
            break;
    }
}

export const getElementValue = (element, isGrouping) => {
    if (!element) {
        return null;
    }
    let _innerName = null;
    let _value;
    let _valueAsList;
    let _type = getElementType(element);

    switch (_type) {
        case 'radio':
            // verificam daca e radio de tip yes/no
            let _yesNo = findYesNoElement(element.getAttribute('name'));

            if (_yesNo) {
                _innerName = null;

                if (isGrouping) {
                    _value = element.checked;

                    if (_value == false) {
                        _value = null;
                    }
                }
                else if (_yesNo.yes.checked) {
                    _value = true;
                }
                else if (_yesNo.no.checked) {
                    _value = false;
                }
                else {
                    _value = null;
                }
            }
            else {
                _innerName = element.value;
                _value = element.checked;

                if (isGrouping && _value == false) {
                    _value = null;
                }
            }

            break;

        case 'checkbox':
            _innerName = null;
            _value = element.checked;

            if (isGrouping && _value == false) {
                _value = null;
            }

            break;

        case 'multiselect':
            _innerName = null;
            let kendoElem = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]');
            if (kendoElem?.getKendoMultiSelect()) {
                _valueAsList = kendoElem.getKendoMultiSelect().dataItems();
            }
            break;

        case 'dropdown':
            _innerName = null;
            let kendoDropdown = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]');
            if (kendoDropdown?.getKendoDropDownList()?.dataItem()) {
                _valueAsList = [kendoDropdown.getKendoDropDownList().dataItem()];
            }
            break;

        case 'date':
            let datepicker = $(element).getKendoDatePicker();
            if (datepicker) {
                _value = convertDateToString(datepicker.value());
            }
            else {
                _value = element.value || element.innerText;
            }
            break;

        case 'time':
            let timepicker = $(element).getKendoTimePicker();
            if (timepicker) {
                _value = convertDateToString(timepicker.value());
            }
            break;

        case 'datetime':
            let datetimepicker = $(element).getKendoDateTimePicker();
            if (datetimepicker) {
                _value = convertDateToString(datetimepicker.value());
            }
            break;

        default:
            // img
            if (element instanceof HTMLImageElement || element.tagName.toLowerCase() === 'img') {
                if (element.classList.contains('signed') && element.src != imgTransparent) {
                    _value = element.src;
                }
                else {
                    _value = null;
                }
            }
            else {
                _value = element.value || element.innerText;
            }
            break;
    }

    if (_valueAsList?.length) {
        _value = _valueAsList.map(x => x.code).join(',');
    }

    return {
        name: element.getAttribute('name') + (_innerName ? "@|!|@" + _innerName : ''),
        value: _value,
        valueAsList: _valueAsList
    };
}

export const initDropdownList = (formComponent) => {
    let dropdownItems = [];

    for (let i = 0; i < formComponent.dropdownList.length; i++) {

        let dropdown = formComponent.dropdownList[i];

        initKendoDropdown(dropdown.name, dropdown.type, formComponent);

        let _elem = getIframeBody().find('input[name="' + dropdown.name + '"]');
        let _field = getFormField(_elem[0], formComponent);

        if (_field?.value) {
            setInitialDataSource(_elem, dropdown.type, _field.value);
        }

        if (dropdown.dataSource?.length > 0) {
            setDropdownDataSource(dropdown.name, dropdown.type, dropdown.dataSource);

            if (_field?.value) {
                setElementValue(_elem[0], _field.value);
            }
        }
        else if (!dropdown.parent) {
            dropdownItems.push({
                item: dropdown,
                parent: null
            });
        }
        else {
            let _parent = getIframeBody().find('input[name="' + dropdown.parent + '"]');
            let _parentField = getFormField(_parent[0], formComponent);

            if (_parentField?.value) {
                dropdownItems.push({
                    item: dropdown,
                    parent: _parentField?.value
                });
            }
            else {
                enableKendoDropdown(dropdown.name, dropdown.type, false);
            }
        }
    }

    if (!formComponent.preview && dropdownItems.length > 0) {
        formComponent.getInitialDataSources(dropdownItems);
    }
}

export const initKendoDropdown = (name, type, formComponent) => {
    let element = getIframeBody().find('input[name="' + name + '"]');
    if (!element) {
        return;
    }

    function convertValues(value) {
        var data = [];
        value = $.isArray(value) ? value : [value];
        for (var idx = 0; idx < value.length; idx++) {
            if (value[idx]) {
                data.push(value[idx].toString());
            }
        }
        return data;
    }

    function convertMultiSelectValues(value) {
        var data = [];
        value = $.isArray(value) ? value : [value];
        for (var idx = 0; idx < value.length; idx++) {
            data.push(value[idx]);
        }
        return data;
    }

    function setPopupWidth(comboboxElem) {
        try {
            let initialWidth = comboboxElem._initialWidth ?? 150;
            let dataSource = comboboxElem.dataSource.data() ?? [];

            const maxWidth = getIframeBody().width();

            let _value = dataSource.reduce(function (result, current) {
                return result.length > current.display.length ? result : current.display;
            }, "");

            let width = getDropdownItemWidth(comboboxElem.element[0], _value);

            if (width < initialWidth) {
                width = initialWidth;
            }
            if (width > 0.95 * maxWidth) {
                width = 0.95 * maxWidth;
            }

            comboboxElem.popup.element.width(width);
        }
        catch { }
    }

    if (type == 'dropdown') {
        if (element.data('kendoDropDownList')) { return; }
        let _initialWidth = element[0].offsetWidth;

        element.kendoDropDownList({
            dataTextField: "display",
            dataValueField: "code",
            filter: "contains",
            dataSource: {
                data: [],
                pageSize: 10
            },
            dataBound: function () {
                setPopupWidth(this);
            },
            open: function () {
                setPopupWidth(this);
            },
            change: function (e) {
                // verificare dropdonw-uri cascadate
                checkCascadeDropdowns(this.element[0], formComponent);

                // verificam autocomplete
                checkAutocompleteParent(this.element[0], formComponent);

                // verificare buton clear
                showHideDropdownClearButton(this);

            },
            suggest: true,
            index: 3,
            popup: {
                appendTo: getIframeBody()
            },
            virtual: {
                itemHeight: 26,
                valueMapper: function (options) {

                    var values = convertValues(options.value);
                    var indices = [];

                    var comboboxTemp = element.data('kendoDropDownList');
                    if (!comboboxTemp) { return; }

                    var data = comboboxTemp.dataSource.data();
                    if (values && values.length > 0) {
                        for (var j = 0; j < data.length; j++) {
                            var item = data[j];
                            if (item["code"] && values.indexOf(item["code"].toString()) > -1) {
                                indices.push(j);
                            }
                        }
                    }

                    if (indices.length > 0) {
                        options.success(indices);
                    }
                }
            }
        });

        let _comboBox = element.data('kendoDropDownList');
        if (_comboBox) {
            _comboBox._initialWidth = _initialWidth;

            if (_comboBox.wrapper) {
                let _button = _comboBox.wrapper.find('.k-input-button');
                let _clearButton = $('<span combobox-name="' + name + '" unselectable="on" class="k-clear-value k-hidden" title="clear" role="button" tabindex="-1"><span class="k-icon k-i-x"></span></span>');

                _clearButton.insertBefore(_button);

                // verificare clear btn
                showHideDropdownClearButton(_comboBox);

                _clearButton.click(function (e) {
                    if (e && e.stopPropagation) {
                        e.stopPropagation();
                    }

                    let _name = this.getAttribute('combobox-name');

                    if (_name) {
                        let _combobox = getIframeBody().find('input[name="' + name + '"]').data('kendoDropDownList');
                        if (_combobox) {
                            _combobox.value(null);
                            _combobox.trigger("change");
                        }
                    }
                });
            }
        }
    }
    else if (type == 'multiselect') {
        if (element.data('kendoMultiSelect')) { return; }

        element.kendoMultiSelect({
            dataTextField: "display",
            dataValueField: "code",
            dataSource: {
                data: [],
                pageSize: 10
            },
            filter: "contains",
            suggest: true,
            index: 3,
            popup: {
                appendTo: getIframeBody()
            },
            dataBound: function () {
                setPopupWidth(this);
            },
            open: function () {
                setPopupWidth(this);
            },
            virtual: {
                itemHeight: 26,
                valueMapper: function (options) {

                    var values = convertMultiSelectValues(options.value);
                    var indices = [];

                    var multiSelect = element.data('kendoMultiSelect');
                    if (!multiSelect) { return; }

                    var data = multiSelect.dataSource.data();
                    if (values && values.length > 0) {
                        for (var j = 0; j < data.length; j++) {
                            var item = data[j];
                            if (values.indexOf(item["code"]) > -1) {
                                indices.push(j);
                            }
                        }
                    }

                    if (indices.length > 0) {
                        options.success(indices);
                    }
                }
            }
        });
    }
}

const initKendoDatePicker = (element) => {
    if (!element) {
        return;
    }
    element = $(element);
    if (element.data('kendoDatePicker')) { return; }

    element.on('focusout', function (e) {
        var value = e.target.value;
        const parts = value.split('.');

        if (parts.length !== 3) {
            element.val("");
            return;
        }

        if (parts.length === 3 && parts[0].length === 2 && parts[1].length === 2 && parts[2].length === 4) {
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);

            const date = new Date(year, month - 1, day);

            if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
                // Invalid date
                element.val("");
            }
        }
        else {
            element.val("");
        }
    });

    element.on('keyup', function (e) {
        
        var value = e.target.value;
        var currentKey = e.originalEvent.key;
        
        if (/[^0-9.]/.test(currentKey)) {
            element.val(value.replace(currentKey, ''));
            return;
        }

    });

    element.kendoDatePicker({

        inputData: true,
        
        //focus pe input cand apas doar pe butonul de deschide datepickerul-ul
        open: function (e) {
            if (!this.value()) {
                this.dateView.calendar.navigate(new Date(), 0);
            }
            createFakeInput();
        },
        popup: {
            appendTo: getIframeBody()
        }
    });
}

const checkCascadeDropdowns = (element, formComponent) => {
    let _field = getFormField(element, formComponent);
    if (!_field) { return; }

    let type = getElementType(element);
    let comboboxType = type == 'dropdown' ? 'kendoDropDownList' :
        type == 'multiselect' ? 'kendoMultiSelect' : null;

    if (!comboboxType) return;

    let _combobox = $(element).data(comboboxType);;
    if (!_combobox) { return; }

    // reset children dropdowns - prima data face reset item + datasouce, apoi facem get-urile
    resetCascadeDropdowns(element.getAttribute('name'), [], formComponent);

    let _dataItem = _combobox.dataItem();
    if (formComponent.dropdownList.some(x => x.parent == _field.htmlTagName)) {
        let _childrenDropdowns = formComponent.dropdownList.filter(x => x.parent == _field.htmlTagName);

        for (let i = 0; i < _childrenDropdowns.length; i++) {
            if (_dataItem) {
                formComponent.getDataSource(_childrenDropdowns[i], (_dataItem.id ?? _dataItem?.code));
            }
            else {
                setDropdownDataSource(_childrenDropdowns[i].name, _childrenDropdowns[i].type, []);
            }
        }
    }
}

const resetCascadeDropdowns = (parentName, visited, formComponent) => {

    // was visited -> return
    if (visited.some(x => x == parentName)) {
        return;
    }

    // add visited
    visited.push(parentName);

    if (formComponent.dropdownList.some(x => x.parent == parentName)) {
        let _childrenDropdowns = formComponent.dropdownList.filter(x => x.parent == parentName);

        for (let i = 0; i < _childrenDropdowns.length; i++) {
            let _childName = _childrenDropdowns[i].name;

            // reset value
            let _elem = getIframeBody().find('input[name="' + _childName + '"]');
            if (_elem[0]) {
                setElementValue(_elem[0], null);
                setDropdownDataSource(_childrenDropdowns[i].name, _childrenDropdowns[i].type, []);
            }

            resetCascadeDropdowns(_childName, visited, formComponent);
        }
    }
}

const checkAutocompleteParent = (element, formComponent) => {
    let _field = getFormField(element, formComponent);
    if (!_field) { return; }

    let _autocompleteParent = getIframeBody().find(`[autocomplete-dropdown-parent="${_field.htmlTagName}"]`);

    if (_autocompleteParent?.length > 0) {
        let _value = getElementValue(element);
        let _hasValue = _value?.value || _value?.valueAsList?.length > 0;

        if (!_hasValue) {
            return;
        }

        for (let i = 0; i < _autocompleteParent.length; i++) {
            let _fieldAutoComplete = getFormField(_autocompleteParent[i], formComponent);
            if (!_fieldAutoComplete) { continue; }

            formComponent.getDataSourceSingle(_value?.value, _fieldAutoComplete, _autocompleteParent[i]);
        }
    }
}

const showHideDropdownClearButton = (comboboxElem, initialValue) => {
    if (!comboboxElem) { return; }

    let value = comboboxElem.value() || initialValue;
    let _button = comboboxElem.wrapper.find('.k-clear-value');
    if (_button) {
        if (value) {
            _button.removeClass('k-hidden');
        }
        else {
            _button.addClass('k-hidden');
        }
    }
}

const createFakeInput = () => {

    return;
    // create invisible dummy input to receive the focus first
    var fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = 0;
    fakeInput.style.height = 0;
    fakeInput.style.fontSize = '16px'; // disable auto zoom

    // zoom/scroll behavior
    getIframeBody().prepend(fakeInput);

    // focus so that subsequent async focus will work
    fakeInput.focus();
    fakeInput.remove();
}

const initKendoDateTimePicker = (element) => {
    if (!element) {
        return;
    }
    element = $(element);
    if (element.data('kendoDateTimePicker')) { return; }

    element.kendoDateTimePicker({
        inputData: true,

        //focus pe input cand apas doar pe butonul de deschide datepickerul-ul
        open: function (e) {
            if (!this.value()) {
                this.dateView.calendar.navigate(new Date(), 0);
            }

            createFakeInput();
        },
        popup: {
            appendTo: getIframeBody()
        }
    });
}

const initKendoTimePicker = (element) => {
    if (!element) {
        return;
    }
    element = $(element);
    if (element.data('kendoTimePicker')) { return; }

    element.on('focusout', function (e) {
        var value = e.target.value;
        const parts = value.split(':');

        if (parts.length !== 2) {
            element.val("");
            return;
        }

        if (parts.length === 2 && parts[0].length === 2 && parts[1].length === 2) {
            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);

            const date = new Date(2000, 1, 1, hours, minutes);

            if (date.getHours() !== hours || date.getMinutes() !== minutes) {
                // Invalid date
                element.val("");
            }
        }
        else {
            element.val("");
        }
    });

    element.on('keyup', function (e) {

        var value = e.target.value;
        var currentKey = e.originalEvent.key;
        if (/[^0-9:]/.test(currentKey)) {
            element.val(value.replace(currentKey, ''));
            return;
        }

    });

    element.kendoTimePicker({
        
        open: function (e) {
            createFakeInput();
        },
        popup: {
            appendTo: getIframeBody()
        }
    });
}

const getInputWidth = (input) => {
    let style = window.getComputedStyle(input, null);
    let fontSize = parseFloat(style.getPropertyValue('font-size'));
    let fontFamily = style.getPropertyValue('font-family');
    let paddingLeft = style.getPropertyValue('padding-left');
    let paddingRight = style.getPropertyValue('padding-right');

    let spanStyle = {
        'position': 'absolute',
        'float': 'left',
        'white-space': 'nowrap',
        'visibility': 'hidden',
        'font-size': fontSize + 'px',
        'font-family': fontFamily,
        'padding-left': paddingLeft,
        'padding-right': paddingRight
    };

    let span = $('<span></span>')
        .text(input.value ? ('00' + input.value) : '00') // mai adaugam doua caractere - pt a se afisa ok si in PDF
        .css(spanStyle)
        .appendTo(getIframeBody());

    let width = span.width();
    if (!isNaN(parseFloat(paddingLeft))) { width += parseFloat(paddingLeft); }
    if (!isNaN(parseFloat(paddingRight))) { width += parseFloat(paddingRight); }

    // remove span
    span.remove();
    return width;
}

const refreshTextareaHeight = (textarea, initialHeight) => {
    // force reload scrollHeight
    textarea.style.setProperty("height", "1px");

    let elemHeight = textarea.scrollHeight;

    if (textarea.classList.contains("enlarge")) {
        elemHeight += 50;
    }

    if (elemHeight > initialHeight) {
        textarea.style.setProperty("height", elemHeight + "px");
    }
    else {
        textarea.style.setProperty("height", initialHeight + "px");
    }
}

const refreshInputWidth = (input, initialWidth) => {
    let elemtWidth = getInputWidth(input);

    if (elemtWidth > initialWidth) {
        input.style.setProperty("width", elemtWidth + "px");
    }
    else {
        input.style.setProperty("width", initialWidth + "px");
    }
}

export const onSpeechInputChange = (currentSpeechInput, transcript) => {
    let _elem = getIframeBody().find('[name="' + currentSpeechInput + '"]');
    if (_elem[0]) {
        let _offsetHeight = _elem[0].offsetHeight;
        let _offsetWidth = _elem[0].offsetWidth;

        _elem[0].value = transcript;

        if (_elem[0].type == 'textarea') {
            refreshTextareaHeight(_elem[0], _offsetHeight);
        }
        else if (_elem[0].tagName.toLowerCase() === 'input' && _elem[0].type != 'checkbox' && _elem[0].type != 'radio') {
            refreshInputWidth(_elem[0], _offsetWidth);
        }
    }
}

const onInputChange = (event, formComponent) => {
    // cand se schimba un input - invalidam semnaturile
    let signatureElements = getIframeBody().find('[signature]');
    for (let i = 0; i < signatureElements.length; i++) {
        signatureElements[i].classList.remove('signed');
    }

    if (!formComponent.preview) {
        // marcam schimbarile pe formular
        formComponent.setFormChanged(true);
    }

    let _isValid = validateElement(event.target, true, formComponent);
    if (!_isValid) {
        setElementValue(event.target, null);
        event.target.classList.add('pulse');
    }
    else {
        event.target.classList.remove('pulse');
    }

    // daca e radio/grouping
    if (event.target.type == 'radio' || event.target.getAttribute('grouping')) {
        pulseOnGroupedElements(event.target, _isValid);
    }

    // daca e group child si s-a completat - selectam automat parintele
    if (_isValid && event.target.getAttribute('grouping-child')) {
        let _groupingParents = getIframeBody().find(`[grouping-parent="${event.target.getAttribute('grouping-child')}"]`);

        if (_groupingParents.length == 1 && (_groupingParents[0].type == 'radio' || _groupingParents[0].type == 'checkbox')) {
            _groupingParents[0].checked = true;
            pulseOnGroupedElements(_groupingParents[0], _isValid);
        }
    }

    // daca e group parent si dropdown sau multiselect
    if (event.target.getAttribute('grouping-parent')) {
        checkDropdownGrouping(event.target);
    }

    // daca e radion si unul din butoane e parent -> atunci resetez copiii lui
    if (_isValid && event.target.type == 'radio') {
        let _groupingParents = getIframeBody().find(`[name="${event.target.getAttribute('name')}"][grouping-parent]`);

        // resetam copiii cand a selectat alt parinte
        for (let i = 0; i < _groupingParents.length; i++) {
            // daca e elementul curent - dam continue
            if (_groupingParents[i] === event.target) {
                continue;
            }

            let _groupingChildren = getIframeBody().find(`[grouping-child="${_groupingParents[i].getAttribute('grouping-parent')}"]`);
            for (let j = 0; j < _groupingChildren.length; j++) {
                setElementValue(_groupingChildren[j], '');
                pulseOnGroupedElements(_groupingChildren[j], _isValid);
            }
        }
    }

    // same-vale
    let _sameValueElems = getIframeBody().find(`[same-value="${event.target.getAttribute('name')}"]`);

    for (let i = 0; i < _sameValueElems.length; i++) {
        if (_isValid) {
            let _elementValue = getElementValue(event.target);
            setElementValue(_sameValueElems[i], _elementValue?.value);
        }
        else {
            setElementValue(_sameValueElems[i], null);
        }
    }
}

const checkDropdownGrouping = (element, initialValue) => {
    let _type = getElementType(element);

    if (_type == 'dropdown' || _type == 'multiselect') {
        let _groupingChildren = getIframeBody().find(`[grouping-child="${element.getAttribute('grouping-parent')}"]`);
        let _value = getElementValue(element);
        let _hasValue = _value?.value || _value?.valueAsList?.length > 0 || !!initialValue;

        if (_groupingChildren?.length > 0) {
            for (let i = 0; i < _groupingChildren.length; i++) {
                if (_hasValue) {
                    enableElement(_groupingChildren[i], true);
                }
                else {
                    setElementValue(_groupingChildren[i], null);
                    enableElement(_groupingChildren[i], false);
                }
            }
        }
    }
}

const enableElement = (element, enableValue) => {
    if (!element) {
        return;
    }

    let _type = getElementType(element);

    switch (_type) {
        case 'dropdown':
            let combobox = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]').data("kendoDropDownList");
            combobox?.enable(enableValue);
            break;

        case 'multiselect':
            let multiselect = getIframeBody().find('input[name="' + element.getAttribute('name') + '"]').data("kendoMultiSelect");
            multiselect?.enable(enableValue);
            break;

        case 'date':
            let datepicker = $(element).getKendoDatePicker();
            datepicker?.enable(enableValue);
            break;

        case 'time':
            let timepicker = $(element).getKendoTimePicker();
            timepicker?.enable(enableValue);
            break;

        case 'datetime':
            let datetimepicker = $(element).getKendoDateTimePicker();
            datetimepicker?.enable(enableValue);
            break;

        default:
            if (enableValue) {
                $(element).removeAttr('disabled');
            }
            else {
                $(element).attr('disabled', 'disabled');
            }
            break;
    }
}

const pulseOnGroupedElements = (element, isValid) => {
    let _findTag = '';

    // daca e radio button - vedem valoarea nenula de pe orice buton din radio buttonul curent
    if (element.type == 'radio') {
        _findTag = `[name="${element.getAttribute('name')}"]`;
    }
    else if (element.getAttribute('grouping')) {
        _findTag = `[grouping="${element.getAttribute('grouping')}"]`;
    }

    let _elements = getIframeBody().find(_findTag);

    for (let i = 0; i < _elements.length; i++) {
        if (isValid) {
            _elements[i].classList.remove('pulse');
        }
        else {
            _elements[i].classList.add('pulse');
        }
    }
}

const convertDateToString = (date) => {
    if (!date || !(date instanceof Date)) return "";

    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);

    return year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
}

const hideElementsOnPrint = (parent, formComponent) => {
    var elements = $(parent).find('[name]');

    // send to server       
    for (let i = 0; i < elements.length; i++) {
        let _field = getFormField(elements[i], formComponent);

        // actualizam value din formField
        if (_field) {
            let _value = getElementValue(elements[i]);

            if (_value?.value || _value?.valueAsList?.length > 0) {
                return;
            }
        }
    }

    parent.style.display = 'none';
}

export const handleElementsOnPrint = (show, formComponent) => {
    var noPrintIfEmptyElems = getIframeBody().find('.no-print-if-empty');
    var noPrintElems = getIframeBody().find('.no-print');
    if (show) {
        for (let i = 0; i < noPrintIfEmptyElems.length; i++) {
            noPrintIfEmptyElems[i].style.display = '';
        }
        for (let i = 0; i < noPrintElems.length; i++) {
            noPrintElems[i].style.display = '';
        }
    }
    else {
        for (let i = 0; i < noPrintIfEmptyElems.length; i++) {
            hideElementsOnPrint(noPrintIfEmptyElems[i], formComponent);
        }
        for (let i = 0; i < noPrintElems.length; i++) {
            noPrintElems[i].style.display = 'none';
        }
    }
}

const helperValidateElement = (element, showAlert, _field, _value, formComponent) => {

    if (element.disabled) return true;

    const language = formComponent.language;

    // verificare DataType
    let _convertedValue = convertValue(_value, _field.dataType);
    let _isValidType = _convertedValue !== undefined;
    if (!_isValidType) {
        if (showAlert) {
            utils.alert.set({
                title: errorMessages.errorName[language],
                text: errorMessages.fieldTypeError[language] + `"${_field.dataType}".`
            });
        }
        return false;
    }

    // verificare required - pt radio/grouping/normal
    if (_field.required) {
        let _isValidRequired = true;

        // daca e element de tip grouping child - atunci vedem daca a fost selectat parintele - daca nu, atunci nu este required
        if (element.getAttribute('grouping-child')) {
            let _groupingParents = getIframeBody().find(`[grouping-parent="${element.getAttribute('grouping-child')}"]`);

            if (_groupingParents.length > 0) {
                // verificam ce valoare are parintele - daca a fost selectat - e required/altfel empty
                let _groupingParentValue = helperGetValueForGroupedElements(_groupingParents, false, formComponent);
                if (_groupingParentValue && _convertedValue == null) {
                    _isValidRequired = false;
                }
            }
            else if (_convertedValue == null) {
                _isValidRequired = false;
            }

            if (element.getAttribute('grouping')) {
                let _groupedValue = getValueForGroupedElements(element, formComponent);
                let _groupingParentValue = helperGetValueForGroupedElements(_groupingParents, true, formComponent);

                if (_groupingParentValue && _groupedValue == null) {
                    _isValidRequired = false;
                }
            }
            else if (element.getAttribute('autocomplete-dropdown-parent')) {
                let _groupingParentValue = helperGetValueForGroupedElements(_groupingParents, true, formComponent);

                if (_groupingParentValue && _convertedValue == null) {
                    _isValidRequired = false;
                }
            }
        }
        // verificare valoare pe toata gruparea
        else if (element.type == 'radio' || element.getAttribute('grouping')) {
            let _groupedValue = getValueForGroupedElements(element, formComponent);

            if (_groupedValue == null) {
                _isValidRequired = false;
            }
        }
        else if (_convertedValue == null) {
            _isValidRequired = false;
        }

        if (!_isValidRequired) {
            if (showAlert) {
                utils.alert.set({
                    title: errorMessages.errorName[language],
                    text: errorMessages.requiredField[language]
                });
            }
            return false;
        }
    }

    // verificare regex
    if (_field.regexExpression) {
        let _regex = new RegExp(_field.regexExpression);
        let _isValidRegex = _field.dataType?.toLowerCase() == "boolean" ?
            _regex.test(_value) || _regex.test(_value ? '1' : '0') :
            _regex.test(_value);

        if (!_isValidRegex) {
            if (showAlert) {
                utils.alert.set({
                    title: errorMessages.errorName[language],
                    text: _field.validationMessage ?
                        _field.validationMessage :
                        errorMessages.regexValidation[language] + `${_field.regexExpression}`
                });
            }
            return false;
        }
    }


    // verificare minValue/maxValue
    if (_field.minValue || _field.maxValue) {
        let _minValue = convertValue(_field.minValue, _field.dataType);
        let _maxValue = convertValue(_field.maxValue, _field.dataType);
        let _isValidMinMaxValue =
            (_minValue ? _minValue <= _convertedValue : true) &&
            (_maxValue ? _convertedValue <= _maxValue : true);

        if (!_isValidMinMaxValue) {
            if (showAlert) {
                utils.alert.set({
                    title: errorMessages.errorName[language],
                    text:
                        _minValue && _maxValue ? errorMessages.minMaxValue[language] + `${_minValue}` + errorMessages.and[language] + `${_maxValue}` :
                            _minValue ? errorMessages.greaterEqual[language] + `${_minValue}` :
                                errorMessages.lessEqual[language] + `${_maxValue}`
                });
            }
            return false;
        }
    }
    // oke
    return true;
}

export const validateElement = (element, showAlert, formComponent) => {
    let _elementValue = getElementValue(element);

    let _field = getFormField(element, formComponent);
    if (!_field) { return true; }

    // pt dropdown/multiselect - facem validare pe fiecare item
    if (_elementValue?.valueAsList?.length > 0) {
        for (let i = 0; i < _elementValue.valueAsList.length; i++) {
            if (!helperValidateElement(element, showAlert, _field, _elementValue.valueAsList[i]?.code, formComponent)) {
                return false;
            }
        }
        return true;
    }
    else {
        return helperValidateElement(element, showAlert, _field, _elementValue?.value, formComponent)
    }
}

const helperGetValueForGroupedElements = (_elements, noRequired, formComponent) => {
    for (let i = 0; i < _elements.length; i++) {
        let _element = _elements[i];
        let _value = getElementValue(_element, true)?.value;
        let _field = getFormField(_element, formComponent);
        if (!_field || (!noRequired && !_field.required)) { continue; }

        // verificare DataType
        let _convertedValue = convertValue(_value, _field.dataType);

        // verificam daca avem o valoare diferita de null/undef - pt a simula required-ul pe grupul radio 
        if (_convertedValue !== undefined && _convertedValue !== null) {
            return _convertedValue;
        }
    }

    return null;
}

/* GRUPARI - verificam tot grupul pt required
*  - radio buttons - grupari de radio - au acelasi name 
*  - tag grouping - grupari de elemente - acelasi grouping tag
*/
export const getValueForGroupedElements = (element, formComponent) => {
    let _findTag = '';

    // daca e radio button - vedem valoarea nenula de pe orice buton din radio buttonul curent
    if (element.type == 'radio') {
        _findTag = `[name="${element.getAttribute('name')}"]`;
    }
    else if (element.getAttribute('grouping')) {
        _findTag = `[grouping="${element.getAttribute('grouping')}"]`;
    }

    let _elements = getIframeBody().find(_findTag);
    return helperGetValueForGroupedElements(_elements, false, formComponent);
}

export const convertValue = (value, type) => {
    if (value === null ||
        value === undefined ||
        value === '' ||
        !type) {
        return null;
    }

    type = type.toLowerCase();
    value = ("" + value).toLowerCase();

    switch (type) {
        case "int":
            return parseInt(value);

        case "boolean":
            if (value == "true") return true;
            if (value == "false") return false;
            if (/^\d+$/.test(value) && !isNaN(parseInt(value))) return parseInt(value) > 0;

            return undefined;

        case "decimal":
            return parseFloat(value);

        case "datetime":
            let _date = moment(value);
            return _date.isValid() ? _date : undefined;

        case "time":
            let _time = moment(value, "HH:mm:ss");
            return _time.isValid() ? _time : undefined;

        default:
            return value;
    }
}

export const runOnErrorImage = (imagesNotFound) => {
    let images = getIframeBody().find('img');

    for (let i = 0; i < images.length; i++) {

        if (images[i].onerror) {
            images[i].onerror = (function (onerror) {
                return function (event) {
                    onErrorImage(event, imagesNotFound);

                    if (onerror) {
                        onerror(event);
                    }
                }
            })(images[i].onerror);
        }
        else {
            images[i].onerror = (e) => onErrorImage(e, imagesNotFound);
        }
    }
}

const onErrorImage = (event, imagesNotFound) => {
    imagesNotFound.current.push(event.target.src);
}

export const setDateAndTime = (date) => {
    let inpDate = getIframeBody().find('#data-curenta');
    let inpHour = getIframeBody().find('#ora-curenta');

    var dayOfMonth = date.getDate();
    var month = date.getMonth() + 1;

    inpDate.val((dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth) + "."
                + (month < 10 ? '0' + month : month) + "."
                + date.getFullYear());

    inpHour.val(date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
}
