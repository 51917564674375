import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const AlertDialog = (props) => {
    let title = props.title;
    if (!title) {
        title = "ALERT";
    }

    let text = props.text;
    if (!text) {
        text = "Server error. Please retry later.";
    }

    return (
        <Dialog title={title} onClose={props.onClose}>
            <p className="k-text-center k-m-3 k-custom-dialog-text" dangerouslySetInnerHTML={{ __html: text }}>
            </p>

            <DialogActionsBar>
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </DialogActionsBar>
        </Dialog>
    );
};
