import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertDialog } from "./_helpers/alertDialog";
import { shortLinkService } from "../_services/shortLink.service";

export const HomePage = () => {
    const params = useParams();

    const [error, setError] = useState(true);

    useEffect(() => {
        if (params.shortLink) {
            setError(false);
            shortLinkService
                .getFullLink({ shortLink: params.shortLink })
                .then(response => {
                    if (response) {
                        window.open(response.url, "_self");
                    }
                    else {
                        setError(true);
                    }
                });
        }
    }, []);

    return (<>
        {error && <AlertDialog
            title="Link invalid"
            text="Va rugam reincercati"
        />}
    </>)

}