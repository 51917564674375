export const validator = {
    validateField,
    validateRequired,
    validateEmail,
    validateUrl,
    validateText,
    validateNumber,
    validatePassword,
    validateDropdown,
    validateMultiSelect,
};

function validateDropdown(value, data, dataItemKey) {
    if (!value || !data || !dataItemKey) { return true; }

    return data.some((item) => { return item[dataItemKey] == value[dataItemKey]; });
}

function validateMultiSelect(value, data, dataItemKey) {
    if (!value || !data || !dataItemKey) { return true; }

    for (let i = 0; i < value.length; i++) {
        if (!validateDropdown(value[i], data, dataItemKey)) {
            return false;
        }
    }
    return true;
}

function validateRequired(text) {
    return text != undefined && text != null && text.toString() != '';
}

function validateEmail(email) {
    if (!email) { return true; }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function validateUrl(url) {
    if (!url) { return true; }

    try {
        new URL(url);
    } catch (_) {
        return false;
    }
    return true;
}

function validateText(text) {
    if (!text) { return true; }

    return /^[^<>%@!.?$0-9]*$/.test(text);
}

function validateNumber(text) {
    if (!text) { return true; }

    return /^(-)?[0-9]+$/.test(text);
}
function validatePassword(text) {
    if (!text) { return true; }

    return /^((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !#$%&"'()*+,-.\:;<=>?@[\]^_`{|}~]).{8,})$/.test(text);
}

function validateField(field) {

    if (field.validations) {
        for (let i = 0; i < field.validations.length; i++) {

            switch (field.validations[i]) {
                case 'required':
                    if (!validator.validateRequired(field.value)) {
                        return `Please enter the ${field.label} field.`;
                    }
                    break;

                case 'text':
                    if (!validator.validateText(field.value)) {
                        return 'Please enter only letters.';
                    }
                    break;

                case 'number':
                    if (!validator.validateNumber(field.value)) {
                        return 'Please enter only digits.';
                    }
                    break;

                case 'password':
                    if (!validator.validatePassword(field.value)) {
                        return 'The password must have a minimum of 8 characters and contain at least one upper case letter, one lower case letter, one number and one special character.';
                    }
                    break;

                case 'email':
                    if (!validator.validateEmail(field.value)) {
                        return 'Please enter a valid email.';
                    }
                    break;

                case 'url':
                    if (!validator.validateUrl(field.value)) {
                        return 'Please enter a valid url.';
                    }
                    break;

                case 'dropdown':
                    if (!validator.validateDropdown(field.value, field.data, field.dataItemKey)) {
                        return 'Please enter a valid item.';
                    }
                    break;

                case 'multiselect':
                    if (!validator.validateMultiSelect(field.value, field.data, field.dataItemKey)) {
                        return 'Please enter valid items.';
                    }
                    break;

                default:

                    if (field.validations[i]) {
                        let _tempValidation = field.validations[i];
                        if (_tempValidation.type == 'interval') {
                            if (!validator.validateNumber(field.value)) {
                                return 'Please enter only digits.';
                            }

                            if (_tempValidation.min != undefined && _tempValidation.max != undefined) {
                                if (field.value < _tempValidation.min || field.value > _tempValidation.max) {
                                    return `Field ${field.label} must be between ${_tempValidation.min} and ${_tempValidation.max}.`;
                                }
                            }
                            else if (_tempValidation.min != undefined) {
                                if (field.value < _tempValidation.min) {
                                    return `Field ${field.label} must be greater than ${_tempValidation.min}.`;
                                }
                            }
                            else if (_tempValidation.max != undefined) {
                                if (field.value > _tempValidation.max) {
                                    return `Field ${field.label} must be less than ${_tempValidation.max}.`;
                                }
                            }

                            
                            break;
                        }
                    }
                    break;
            }
        }
    }

    return '';
}
