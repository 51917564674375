import React, { useEffect, useState, useRef } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import printJS from 'print-js';
import QRCode from "react-qr-code";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ValidatedInput } from "../_helpers/customValidator";
import { getIframeBody, getIframeHead, getElementValue, getFormField, handleElementsOnPrint, validateElement, clearPulseValidation, setDateAndTime } from "../../_helpers/iFrame";
import { utils } from '../../_helpers/utils'; 
import { externalService } from '../../_services/external.service';
import { errorMessages } from "../../_helpers/errorMessages.js";

export const ExportForm = (props) => {

    const { formResponseId, setFormResponseId, formFields, shortLink, formChanged, setFormChanged, language,
            isFinalized, hideQrCode, imagesNotFound, preview, initDataLoaded, awaitableDropdowns, date } = props;

    const [openDownloadDialog, setOpenDownloadDialog] = useState();
    const [openQRCodeDialog, setOpenQRCodeDialog] = useState();
    const [saveFormQueued, setSaveFormQueued] = useState();

    const pdfExportComponent = useRef(null);

    useEffect(() => {
        if (initDataLoaded && saveFormQueued) {
            checkAwaitableDropdowns();
        }
    }, [initDataLoaded]);

    const checkAwaitableDropdowns = () => {
        for (let dropdownName of awaitableDropdowns) {
            let dropdown = getIframeBody().find('input[name="' + dropdownName + '"]');

            if (!dropdown?.getKendoDropDownList()?.dataItem()) {
                setTimeout(checkAwaitableDropdowns, 1000);
                break;
            }
        }

        onSaveForm();
        setSaveFormQueued(false);
    }

    const handleOpenDownloadDialog = () => {
        if (formChanged) {
            utils.alert.set({
                title: errorMessages.errorName[language],
                text: errorMessages.saveThenDownloadForm[language]
            });
            return;
        }

        setOpenDownloadDialog(true);
    }

    const onPrintForm = () => {
        // daca avem formResponse - merge pe download din api
        if (formResponseId) {

            if (formChanged) {
                utils.alert.set({
                    title: errorMessages.errorName[language],
                    text: errorMessages.saveThenPrintForm[language]
                });
                return;
            }

            let config = window.MedisFhirConfig?.apiUrl;
            if (!config) {
                return;
            }

            let url = `${config}/external/form/downloadfile/${language}/${shortLink}`;
            printJS(url);
        }
    }

    const saveOrUpdateForm = (dataUri, signatures) => {
        externalService.saveOrUpdateForm(
                        {
                            shortLink: shortLink,
                            formResponseId: formResponseId,
                            formFields: formFields,
                            responseFile: dataUri,
                            language: language,
                            signatures: signatures
                        })
                        .then(response => {
                            if (response) {

                                if (response.code == '202') {
                                    setFormResponseId(response.message);
                                    setFormChanged(false);
                                }
                                else if (response.code == '407') {
                                    if (response.message) {
                                        let tagNames = response.message.split(';');
                                        var elements = getIframeBody().find('[name]');

                                        for (let i = 0; i < elements.length; i++) {
                                            let _value = getElementValue(elements[i]);

                                            if (tagNames.indexOf(_value.name) >= 0) {
                                                elements[i].classList.add('pulse');
                                            }
                                        }
                                    }

                                    // scroll top
                                    document.body.scrollTop = 0;
                                    window.scrollTo(0, 0);

                                    utils.alert.set({
                                        title: errorMessages.errorName[language],
                                        text: errorMessages.invalidFields[language]
                                    });

                                    // dupa 10 secunde dispare clasa de validare pulse
                                    setTimeout(clearPulseValidation, 10000);

                                    setDateAndTime(date);

                                    return;
                                }                               

                                utils.alert.set({
                                    title: errorMessages.success[language],
                                    text: errorMessages.savedForm[language]
                                });
                            }
                        });
    }

    const convertDOM = (callback) => {

        var _gIsValid = true;
        var elements = getIframeBody().find('[name]');
        var focusOnFirstInvalid = false;

        for (let i = 0; i < elements.length; i++) {
            let _isValid = validateElement(elements[i], false, {
                formFields: formFields,
                preview: preview
            });

            // error
            if (!_isValid) {
                _gIsValid = _isValid;
                elements[i].classList.add('pulse');

                if (!focusOnFirstInvalid) {
                    elements[i].scrollIntoView();
                    focusOnFirstInvalid = true;
                }
            }
            else {
                elements[i].classList.remove('pulse');
            }
        }

        if (!_gIsValid) {
            utils.alert.set({
                title: errorMessages.errorName[language],
                text: errorMessages.invalidFields[language]
            });

            // dupa 10 secunde dispare clasa de validare pulse
            setTimeout(clearPulseValidation, 10000);
            return;
        }

        setDateAndTime(new Date());

        // send to server       
        for (let i = 0; i < elements.length; i++) {
            let _field = getFormField(elements[i], {
                preview: preview,
                formFields: formFields
            });

            // actualizam value din formField
            if (_field) {
                let _value = getElementValue(elements[i]);

                _field.value = _value?.value;
                if (_value?.valueAsList) {
                    _field.valueAsList = _value?.valueAsList;
                }
            }
        }

        let element = pdfExportComponent.current || getIframeBody()[0] || document.body;

        let signatures = [];

        try {
            utils.loading.setOnly(true);

            // ascundem elementele no-print
            handleElementsOnPrint(false,
                {
                    formFields: formFields,
                    preview: preview
                });

            // dam disable la stilul responsive  
            var responsiveStyle = getIframeHead().find('style[id="responsive-code"]');

            if (responsiveStyle && responsiveStyle.length > 0) {
                $(responsiveStyle[0]).prop('disabled', 'true');
            }

            drawDOM(element,
                {
                    paperSize: "A4",
                    margin: "10mm",
                    scale: 0.55,
                    forcePageBreak: ".page-break",
                    keepTogether: ".prevent-split"
                })
                .then((group) => {
                    // afisam inapoi elementele no-print
                    handleElementsOnPrint(true,
                        {
                            formFields: formFields,
                            preview: preview
                        });

                    // punem la loc stilul responsive
                    var responsiveStyle = getIframeHead().find('style[id="responsive-code"]');

                    if (responsiveStyle && responsiveStyle.length > 0) {
                        $(responsiveStyle[0]).prop('disabled', '');
                    }

                    let signatureElements = getIframeBody().find('[signature]');
                    let index = 0;

                    group.traverse(function (element) {
                        if (element.nodeType == 'Image') {
                            let _currentSrc = element.src();

                            if (imagesNotFound.current.some(x => x == _currentSrc)) {
                                return;
                            }

                            for (let k = 0; k < signatureElements.length; k++) {
                                let _found = signatureElements[k].src == _currentSrc;
                                if (_found) {
                                    signatures.push({
                                        Index: index,
                                        Width: element.rect()?.size?.width ?? signatureElements[k].width,
                                        Height: element.rect()?.size?.height ?? signatureElements[k].height
                                    })
                                }
                            }

                            // increment index in group
                            index++;
                        }
                    });

                    return exportPDF(group);
                })
                .then(dataUri => callback(dataUri, signatures));
        }
        catch {
            setDateAndTime(date);
            utils.loading.setOnly(false);
        }
    }

    const onSaveForm = () => {

        if (!initDataLoaded) {
            setSaveFormQueued(true);
            utils.loading.setOnly(true);
            return;
        }
        convertDOM(saveOrUpdateForm);
    }

    const getSaveButton = (language) => {
        let buttonName = "";
        switch (language) {
            case "EN":
                buttonName = "Save Form";
                break;
            case 'FR':
                buttonName = "Enregistrer le formulaire";
                break;
            default:
                buttonName = "Salvare Formular";
        }

        return buttonName;
    }

    const getPrintButton = (language) => {
        let buttonName = "";
        switch (language) {
            case "EN":
                buttonName = "Print Form";
                break;
            case 'FR':
                buttonName = "Formulaire d'impression";
                break;
            default:
                buttonName = "Tiparire Formular";
        }

        return buttonName;
    }

    const getDownloadButton = (language) => {
        let buttonName = "";
        switch (language) {
            case "EN":
                buttonName = "Download PDF";
                break;
            case 'FR':
                buttonName = `T${String.fromCharCode(233)}l${String.fromCharCode(233)}charger le PDF`;
                break;
            default:
                buttonName = "Descarcare PDF";
        }

        return buttonName;
    }

    const getQRCodeButton = (language) => {
        let buttonName = "";
        switch (language) {
            case "EN":
                buttonName = "QR Code";
                break;
            case 'FR':
                buttonName = 'QR Code';
                break;
            default:
                buttonName = "Cod QR";
        }

        return buttonName;
    }

    const downloadButton = <button
        id="_downloadPdfButton"
        type={"button"}
        className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-warning k-rounded-lg k-ml-3"
        onClick={handleOpenDownloadDialog}
        disabled={formChanged}
    >
        <strong>{getDownloadButton(language)}</strong>
    </button>;

    return (<>
    <div className="k-card-footer k-text-center">
        <div className="k-mb-3">
            {!preview && <button
                    id="_saveFormButton"
                    type={"button"}
                    className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-secondary k-rounded-lg"
                    onClick={onSaveForm}
                    disabled={isFinalized}
            >
                <strong>{getSaveButton(language)} </strong>
                </button>}
                {preview && downloadButton}
            {!hideQrCode && <button
                id="_qrCodeButton"
                type={"button"}
                className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-success k-rounded-lg k-ml-3"
                onClick={() => setOpenQRCodeDialog(true)}
            >
                <strong>{getQRCodeButton(language)}</strong>
            </button>}
        </div>
            {formResponseId && !preview && <div className="k-mb-3">
                 <button
                    id="_printFormButton"
                    type={"button"}
                    className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-info k-rounded-lg"
                    onClick={onPrintForm}
                    disabled={formChanged}
                >
                    <strong>{getPrintButton(language)}</strong>
                </button>
                {downloadButton}
            </div>}
        </div>
        {openDownloadDialog && <DownloadDialog
            preview={preview}
            convertDOM={convertDOM}
            language={language}
            url={`/external/form/downloadfile/${language}/${shortLink}`}
            onClose={() => setOpenDownloadDialog(false)} />}

        {openQRCodeDialog && <QRCodeDialog
            language={language}
            onClose={() => setOpenQRCodeDialog(false)} />}
    </>)
}

const DownloadDialog = (props) => {
    const [fileName, setFileName] = React.useState('');

    const getHtmlText = (language) => {
        let text = {
            cancelButton: '',
            saveButton: '',
            title: '',
            textInfo: '',
            fieldFileName: ''
        };

        switch (language) {
            case "EN":
                text.cancelButton = 'Cancel';
                text.saveButton = 'Download';
                text.title = 'Download PDF';
                text.textInfo = 'Please fill out the file name.';
                text.fieldFileName = 'File Name';
                break;
            case 'FR':
                text.cancelButton = 'Annuler';
                text.saveButton = `T${String.fromCharCode(233)}l${String.fromCharCode(233)}charger`;
                text.title = `T${String.fromCharCode(233)}l${String.fromCharCode(233)}charger le PDF`;
                text.textInfo = 'Veuillez remplir le nom du fichier.';
                text.fieldFileName = 'Nom de fichier';
                break;
            default:
                text.cancelButton = 'Anuleaza';
                text.saveButton = 'Descarca';
                text.title = 'Descarcare PDF';
                text.textInfo = 'Te rugam sa completezi numele fisierului.';
                text.fieldFileName = 'Nume Fisier';
        }
        return text;
    }

    const htmlText = getHtmlText(props.language);

    const onKeyDownFilter = (event) => {
        if (event.key === 'Enter') {
            onDownload();
        }
    }

    const downloadPreviewForm = (dataUri) => {
        const binaryData = atob(dataUri.split(',')[1]);

        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = fileName + '.pdf';
        downloadLink.target = '_blank';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        utils.loading.setOnly(false);
    }

    const onDownload = () => {
        if (!fileName) {
            utils.alert.set({
                title: errorMessages.errorName[props.language],
                text: errorMessages.requiredField[props.language]
            });
            return;
        }

        if (props.preview) {
            props.convertDOM(downloadPreviewForm);
        }
        else {
            let config = window.MedisFhirConfig?.apiUrl;
            if (!config) {
                return;
            }

            let element = document.createElement("a");
            element.href = config + props.url + `?fileName=${fileName}`;
            element.style.display = 'none';
            element.target = '_blank';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();

            element.remove();
        }
        
        props.onClose();
    }

    return (
        <Dialog title={htmlText.title} onClose={props.onClose} className="k-custom-dialog">
            <div className="k-form k-mb-2 k-pb-1 bottom-line">
                <span className="k-display-block">{htmlText.textInfo} </span>
            </div>
            <div className="k-form k-mb-3 k-pl-3 k-pr-3">
                <div className="row k-mb-3 ">
                    <div className="k-form-fieldset col-12 k-mt-0">
                        <ValidatedInput
                            key="fileName"
                            name={htmlText.fieldFileName}
                            label={htmlText.fieldFileName}
                            value={fileName}
                            onChange={(event) => setFileName(event.value)}
                            onKeyDown={onKeyDownFilter}
                        />
                    </div>
                </div>
                <div className="k-form-buttons k-text-center k-display-block k-mt-3">
                    <button type={"button"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-m-2"
                        onClick={props.onClose}>
                        {htmlText.cancelButton}
                    </button>
                    <button type={"button"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-m-2"
                        onClick={onDownload}
                        disabled={!fileName}>
                        {htmlText.saveButton}
                    </button>
                </div>
            </div>
        </Dialog>
    );
}

const QRCodeDialog = (props) => {

    const getUrl = () => {
        let url = window.location.origin;
        let pathname = window.location.pathname;

        if (url && url.slice(-1) !== '/') {
            url += '/';
        }

        // hide QrCode
        url += 'tablet';

        if (pathname.slice(0, 1) !== '/') {
            pathname = '/' + pathname;
        }

        // hide QrCode
        url += pathname;

        return url;
    }

    const getHtmlText = (language) => {
        let text = {
            cancelButton: '',
            title: ''
        };
        switch (language) {
            case "EN":
                text.cancelButton = 'Close';
                text.title = 'QR Code';
                break;
            case 'FR':
                text.cancelButton = 'Fermer';
                text.title = 'QR Code';
                break;
            default:
                text.cancelButton = 'Inchide';
                text.title = 'Cod QR';
        }
        return text;
    }

    const htmlText = getHtmlText(props.language);
    const url = getUrl();

    return (
        <Dialog title={htmlText.title} onClose={props.onClose} className="k-custom-dialog">
            <div className="k-form k-mb-3 k-pl-3 k-pr-3">
                <div className="row k-mb-3">
                    <div className="k-form-fieldset col-12 k-mt-0">
                        <QRCode
                            value={url}
                            size={400}
                            style={{ maxWidth: "100%", width: "100%" }}
                        />
                    </div>
                </div>
                <div className="k-form-buttons k-text-center k-display-block k-mt-3">
                    <button type={"button"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-m-2"
                        onClick={props.onClose}>
                        {htmlText.cancelButton}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};
