import React from "react";

export const Loading = (props) => {
    return (
        <div>
            <div className="k-overlay k-loading-overlay"></div>
            <div className="k-loading-container">
                <img src={"../../images/loading.svg"} className="k-loading-img" alt={"Medis FHIR"} />
                <span className="k-loading-txt">{props.text}</span>
            </div>
        </div>
    );
};
