export const errorMessages = {
    errorName: {
        "RO": "Eroare",
        "EN": "Error",
        "FR": "Erreur"
    },
    invalidUrl: {
        "RO": `Url-ul nu este valid. Va rugam sa specificati formularul, codul de pacient si identificatorul medis. (ex. ${window.location.origin} /external/form/{formular}/{codPacient}/{identificatorMedis}).`,
        "EN": `The url is not valid. Please specify the form, patient code and medical identifier. (e.g ${window.location.origin} /external/form/{form}/{patientCode}/{medisIdentifier}).`,
        "FR": `L'url n'est pas valide. Veuillez pr${String.fromCharCode(233)}ciser le formulaire, le code patient et l'identifiant medis. (par exemple ${window.location.origin} /external/form/{formulaire}/{codePatient}/{identifiantMedis}).`
    },
    generalError: {
        "RO": "<div class='k-text-center'><strong> A intervenit o eroare. Va rugam sa reveniti mai tarziu.</strong> <br /> <strong>Va multumim!</strong></div>",
        "EN": "<div class='k-text-center'><strong> An error occurred. Please come back later.</strong> <br /> <strong>Thank you!</strong></div>",
        "FR": "<div class='k-text-center'><strong> Une erreur s'est produite. Merci de revenir plus tard.</strong> <br /> <strong>Merci!</strong></div>"
    },
    fieldTypeError: {
        "RO": `Valoarea pentru acest camp trebuie sa fie de tipul `,
        "EN": `The value for this field must be of the type `,
        "FR": `La valeur de ce champ doit ` + String.fromCharCode(234)+`tre du type `
    },
    requiredField: {
        "RO": `Acest camp este obligatoriu.`,
        "EN": `This field is required.`,
        "FR": `Ce champ est requis.`
    },
    regexValidation: {
        "RO": `Valoarea pentru acest camp nu corespunde cu expresia regex `,
        "EN": `The value for this field does not match the regex expression `,
        "FR": `La valeur de ce champ ne correspond pas ` + String.fromCharCode(224) + ` l'expression r` + String.fromCharCode(233) +`guli${String.fromCharCode(232)}re `
    },
    minMaxValue: {
        "RO": `Valoarea pentru acest camp trebuie sa fie intre `,
        "EN": `The value for this field must be between  `,
        "FR": `La valeur de ce champ doit ` + String.fromCharCode(234) +`tre comprise entre `
    },
    and: {
        "RO": ` si `,
        "EN": ` and  `,
        "FR": ` et `
    },
    greaterEqual: {
        "RO": `Valoarea pentru acest camp trebuie sa fie mai mare sau egala cu `,
        "EN": `The value for this field must be greater than or equal to  `,
        "FR": `La valeur de ce champ doit ` + String.fromCharCode(234) + `tre sup` + String.fromCharCode(233) +`rieure ou ` + String.fromCharCode(233) + `gale ` + String.fromCharCode(224)
    },
    lessEqual: {
        "RO": `Valoarea pentru acest camp trebuie sa fie mai mica sau egala cu `,
        "EN": `The value for this field must be less than or equal to  `,
        "FR": `La valeur de ce champ doit ` + String.fromCharCode(234) + `tre inf` + String.fromCharCode(233) + `rieure ou ` + String.fromCharCode(233) + `gale ` + String.fromCharCode(224)
    },
    invalidFields: {
        "RO": 'Unele campuri nu sunt valide. Va rugam sa le corectati!',
        "EN": 'Some fields are not valid. Please correct them!',
        "FR": 'Certains champs ne sont pas valides. Merci de les corriger!'
    },
    success: {
        "RO": 'Succes',
        "EN": 'Success',
        "FR": 'Succ�s'
    },
    savedForm: {
        "RO": 'Formularul a fost salvat cu succes!',
        "EN": 'The form has been successfully saved!',
        "FR": 'Le formulaire a ' + String.fromCharCode(233) + 't' + String.fromCharCode(233) + ' enregistr' + String.fromCharCode(233) + ' avec succ' + String.fromCharCode(232) +'s!'
    },
    saveThenPrintForm: {
        "RO": 'Au fost facute modificari. Salvati formularul, apoi il puteti tipari!',
        "EN": 'Changes have been made. Save the form, then you can print it!',
        "FR": "Des modifications ont " + String.fromCharCode(233) + "t" + String.fromCharCode(233) + " apport" + String.fromCharCode(233) + "es. Enregistrez le formulaire, vous pourrez ensuite l'imprimer!"
    },
    saveThenDownloadForm: {
        "RO": 'Au fost facute modificari. Salvati formularul, apoi il puteti descarca!',
        "EN": 'Changes have been made. Save the form, then you can download it!',
        "FR": "Des modifications ont " + String.fromCharCode(233) + "t" + String.fromCharCode(233) + " apport" + String.fromCharCode(233) + "es. Enregistrez le formulaire, vous pourrez ensuite le t" + String.fromCharCode(233) + "l" + String.fromCharCode(233) + "charger!"
    }
}